import { Helmet } from 'react-helmet';
import { useFormatMessage } from '../../hooks';

function Domain() {
  const domain = window.location.hostname.replace("www.","").replace(/(^\w)|([-\s]\w)/g, match => match.toUpperCase())
  return (
    <>
      <Helmet>
        <title>{domain} | For Sale</title>
      </Helmet>
      <div class="fullscreen-sperator">
        <div>
          <div class="domain-nav">
            <div class="domain-nav-wrapper">
              <h1 class="any-domains">ANY-Domains.com</h1>
            </div>
          </div>
          <div class="domain-content">
            <div class="domain-content-wrapper">
              <div class="domain-content-block">
                <div class="domain-content-text">{useFormatMessage("domain.first")}</div>
                <h1 class="domain-name">{domain}</h1>
                <div class="domain-content-text">{useFormatMessage("domain.second")}</div>
              </div>
              <div class="domain-content-block right">
                <a className="button-2 w-button" href={`mailto:info@any-domains.uk?subject=Enquiring for ${domain}`} target="_blank" rel="noopener">{useFormatMessage("domain.enquire")}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="footer-wrapper">
            <div>
              <a href="https://webflow.com/" target="_blank" class="webflow-link w-inline-block"><img src="images/webflow-w-small2x_1webflow-w-small2x.png" width="15" alt="" class="webflow-logo-tiny"/>
                <div class="paragraph-tiny">ANY-Web Limited</div>
              </a>
            </div>
            <div class="footer-links">
              <a href="https://www.facebook.com/webflow/" target="_blank" class="footer-item">Facebook</a>
              <a href="https://twitter.com/webflow" target="_blank" class="footer-item">Twitter</a>
              <a href="https://www.instagram.com/webflowapp/" target="_blank" class="footer-item">Instagram</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Domain