import React, { useEffect } from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import Domain from '../domainSale';
import DynamicPage from '../dynamicPage';
import paths from './paths';
import Search from '../searchResult';
import { useDomainData } from '../../hooks/useDomainData';
import useDynamicCSS from '../../hooks/useDynamicCSS';
import { useSelector } from 'react-redux';
import NotFound from '../notFound';
// import PrivateRoute from './PrivateRoute';




const RouterComponent = () => {
  useDomainData()
  const loaded = useSelector(state => state.domainData.data)
  const pages = loaded?.pages
  const cssFilePath = loaded?.css
  const columns = loaded?.columns
  useDynamicCSS("master.css");
  useDynamicCSS(cssFilePath);


  if (loaded) {
    return (
      <BrowserRouter>
        <Routes>
          {pages.map((page, index) => (
            <Route key={index} path={page.DT_Setting_URL} element={<DynamicPage centerJSX={page.DT_Content_EN} title={page.DT_Setting} columns={(page.DT_ColumnLeftWidth, page.DT_ColumnLeftContent, page.DT_ColumnRightWidth, page.DT_ColumnRightContent )} defaultColumns={columns}/>} exact />
          ))}
          <Route path="/" element={<Navigate replace to={`${pages[0].DT_Setting_URL}`} />} />
          <Route exact path={paths.SEARCH} element={<Search/>} />
          <Route exact path={paths.DOMAIN} element={<Domain/>} />

          <Route path="*" element={<Navigate replace to={`${pages[0].DT_Setting_URL}`} />} />
        </Routes>
      </BrowserRouter>
    );
  }
  else {
    return (
      <p>Loading...</p>
    )
  }
};

export default RouterComponent;
