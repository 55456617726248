// import React from 'react';
// import { Link } from 'react-router-dom';

// const MainMenu = ({ items }) => {
//   console.log("ITEMS:::", items)
//   const currentPage = window.location.pathname.substring(1);
//   return (
//     <nav role="navigation" class="navigation-items w-nav-menu">
//       {items.map((item, index) => (
//         <Link
//           key={index}
//           to={"/"+item.DT_Setting_URL}
//           style={{ textDecoration: 'none' }}
//           className={currentPage === item.DT_Setting_URL ? "navigation-item w-nav-link w--current":"navigation-item w-nav-link"}
//         >
//           {item.DT_Setting_URL}
//         </Link>
//       ))}
//     </nav>
//   );
// };

// export default MainMenu;


// STEP 1------------------------------------------------------------------

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

// const MainMenu = ({ items }) => {
//   const currentPage = window.location.pathname.substring(1);
//   const [openDropdown, setOpenDropdown] = useState(null);

//   const handleDropdownToggle = (index) => {
//     if (openDropdown === index) {
//       setOpenDropdown(null);
//     } else {
//       setOpenDropdown(index);
//     }
//   };

//   return (
//     <nav role="navigation" className="navigation-items w-nav-menu">
//       {items.map((item, index) => (
//         <div key={index} className="dropdown-wrapper">
//           {item.items && item.items.length > 0 ? (
//             <>
//               <button
//                 className={
//                   currentPage === item.DT_Setting_URL
//                     ? "navigation-item w-nav-link w--current"
//                     : "navigation-item w-nav-link"
//                 }
//                 onClick={() => handleDropdownToggle(index)}
//               >
//                 {item.DT_Setting_URL}
//               </button>
//               {openDropdown === index && (
//                 <div className="dropdown-content">
//                   {item.items.map((subItem, subIndex) => (
//                     <Link
//                       key={subIndex}
//                       to={"/" + subItem.DT_Setting_URL}
//                       style={{ textDecoration: 'none' }}
//                       className="dropdown-item"
//                     >
//                       {subItem.DT_Setting_URL}
//                     </Link>
//                   ))}
//                 </div>
//               )}
//             </>
//           ) : (
//             <Link
//               to={"/" + item.DT_Setting_URL}
//               style={{ textDecoration: 'none' }}
//               className={
//                 currentPage === item.DT_Setting_URL
//                   ? "navigation-item w-nav-link w--current"
//                   : "navigation-item w-nav-link"
//               }
//             >
//               {item.DT_Setting_URL}
//             </Link>
//           )}
//         </div>
//       ))}
//     </nav>
//   );
// };

// export default MainMenu;

// Step 2-------------------------------------------------

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

// const MainMenu = ({ items }) => {
//   const currentPage = window.location.pathname.substring(1);

//   const [openDropdown, setOpenDropdown] = useState(null);

//   const handleDropdownToggle = (index) => {
//     if (openDropdown === index) {
//       setOpenDropdown(null);
//     } else {
//       setOpenDropdown(index);
//     }
//   };

//   const handleDropdownMouseEnter = (index) => {
//     setOpenDropdown(index);
//   };

//   const handleDropdownMouseLeave = () => {
//     setOpenDropdown(null);
//   };

//   return (
//     <nav role="navigation" className="navigation-items w-nav-menu">
//       {items.map((item, index) => (
//         <div
//           key={index}
//           className="dropdown-wrapper"
//           onMouseEnter={() => handleDropdownMouseEnter(index)}
//           onMouseLeave={handleDropdownMouseLeave}
//         >
//           {item.items && item.items.length > 0 ? (
//             <>
//               <button
//                 className={
//                   currentPage === item.DT_Setting_URL
//                     ? "navigation-item w-nav-link w--current"
//                     : "navigation-item w-nav-link"
//                 }
//                 onClick={() => handleDropdownToggle(index)}
//               >
//                 {item.DT_Setting_URL}
//               </button>
//               {openDropdown === index && (
//                 <div className="dropdown-content">
//                   {item.items.map((subItem, subIndex) => (
//                     <Link
//                       key={subIndex}
//                       to={"/" + subItem.DT_Setting_URL}
//                       style={{ textDecoration: 'none' }}
//                       className="dropdown-item"
//                     >
//                       {subItem.DT_Setting_URL}
//                     </Link>
//                   ))}
//                 </div>
//               )}
//             </>
//           ) : (
//             <Link
//               to={"/" + item.DT_Setting_URL}
//               style={{ textDecoration: 'none' }}
//               className={
//                 currentPage === item.DT_Setting_URL
//                   ? "navigation-item w-nav-link w--current"
//                   : "navigation-item w-nav-link"
//               }
//             >
//               {item.DT_Setting_URL}
//             </Link>
//           )}
//         </div>
//       ))}
//     </nav>
//   );
// };

// export default MainMenu;

// Step 3 ---------------------------------------------------------

// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';


// const MainMenu = ({ items, customStyles }) => {
//   const currentPage = window.location.pathname.substring(1);
//   const [openDropdown, setOpenDropdown] = useState(null);
//   const {type, number} = customStyles
//   console.log("customStyles:  type:",type, " number:",number)
//   useEffect(() => {
//     const handleDocumentClick = (e) => {
//       if (openDropdown !== null && !e.target.closest('.dropdown-wrapper')) {
//         setOpenDropdown(null);
//       }
//     };

//     document.addEventListener('click', handleDocumentClick);

//     return () => {
//       document.removeEventListener('click', handleDocumentClick);
//     };
//   }, [openDropdown]);

//   const handleDropdownToggle = (index) => {
//     setOpenDropdown(index);
//   };

//   return (
//     <nav role="navigation" className="navigation-items w-nav-menu">
//       {items.map((item, index) => (
//         <div key={index} className="dropdown-wrapper">
//           {item.items && item.items.length > 0 ? (
//             <>
//               <Link to={"/" + item.DT_Setting_URL}>
//                 <button
//                   className={
//                     currentPage === item.DT_Setting_URL
//                     ? `navigation-item nav-${type}${number}-item w-nav-link w--current`
//                     : `navigation-item nav-${type}${number}-item w-nav-link`
//                   }
//                   onMouseEnter={() => handleDropdownToggle(index)}
                  
//                   >
//                   {item.DT_Setting}
//                 </button>
//               </Link>
//               {openDropdown === index && (
//                 <div className="dropdown-content">
//                   {item.items.map((subItem, subIndex) => (
//                     <Link
//                       key={subIndex}
//                       to={"/" + subItem.DT_Setting_URL}
//                       className={`dropdown-item nav-${type}${number}-dropdown`}
//                     >
//                       {subItem.DT_Setting}
//                     </Link>
//                   ))}
//                 </div>
//               )}
//             </>
//           ) : (
//             <Link
//               to={"/" + item.DT_Setting_URL}
//               className={
//                 currentPage === item.DT_Setting_URL
//                   ? `navigation-item nav-${type}${number}-item w-nav-link w--current`
//                   : `navigation-item nav-${type}${number}-item w-nav-link`
//               }
//             >
//               {item.DT_Setting}
//             </Link>
//           )}
//         </div>
//       ))}
//     </nav>
//   );
// };

// export default MainMenu;

// Step 4--------------------------

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const MainMenu = ({ items, customStyles }) => {
  const currentPage = window.location.pathname.substring(1);
  const [openDropdown, setOpenDropdown] = useState(null);
  const { type, number } = customStyles;
  console.log("customStyles:  type:", type, " number:", number);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (openDropdown !== null && !e.target.closest('.dropdown-wrapper')) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [openDropdown]);

  // Organize items into rows based on DT_Level1
  const rows = items.reduce((acc, item) => {
    const row = item.DT_Level1 === '01' ? 1 : item.DT_Level1 === '02' ? 2 : 0;

    if (!acc[row]) {
      acc[row] = [];
    }

    acc[row].push(item);

    return acc;
  }, []);

  const handleDropdownToggle = (index) => {
    setOpenDropdown(index);
  };

  const renderRow = (rowItems, rowIndex) => (
    <nav key={rowIndex} role="navigation" className={`navigation-items navigation-${type}${number}-items w-nav-menu`}>
      {rowItems.map((item, index) => (
        <div key={index} className="dropdown-wrapper">
          {item.items && item.items.length > 0 ? (
            <>
              <Link to={"/" + item.DT_Setting_URL}>
                <button
                  className={
                    currentPage === item.DT_Setting_URL
                      ? `navigation-item nav-${type}${number}-item w-nav-link w--current`
                      : `navigation-item nav-${type}${number}-item w-nav-link`
                  }
                  onMouseEnter={() => handleDropdownToggle(index)}
                >
                  {item.DT_Setting}
                </button>
              </Link>
              {openDropdown === index && (
                <div className="dropdown-content">
                  {item.items.map((subItem, subIndex) => (
                    <Link
                      key={subIndex}
                      to={"/" + subItem.DT_Setting_URL}
                      className={`dropdown-item nav-${type}${number}-dropdown`}
                    >
                      {subItem.DT_Setting}
                    </Link>
                  ))}
                </div>
              )}
            </>
          ) : (
            <Link
              to={"/" + item.DT_Setting_URL}
              className={
                currentPage === item.DT_Setting_URL
                  ? `navigation-item nav-${type}${number}-item w-nav-link w--current`
                  : `navigation-item nav-${type}${number}-item w-nav-link`
              }
            >
              {item.DT_Setting}
            </Link>
          )}
        </div>
      ))}
    </nav>
  );

  return (
    <div class={`navigation-items-right `}>
      <div class={`navigation-wrap nav-${type}${number}-wrapper`}>
        <div style={{flexDirection: "column"}}>{rows.map((row, index) => renderRow(row, index))}</div>
      </div>
    </div>
  );
};

export default MainMenu;