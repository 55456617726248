import React, { useState } from 'react'
import Dropdown from 'react-dropdown';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom';
import { setUserLocale } from '../../state/actions/preferences';
import { availableLocales, awcg_domain_u1, flags } from '../../utils';
import { useFormatMessage } from '../../hooks';

import TopMenu from './topMenu';
import MainMenu from './mainMenu';

const Navigation = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [message, setMessage] = useState("")
    const changeLocaleHandler = (local) => {
        dispatch(setUserLocale(local));
    };
    const onSelect = (value) =>{
        changeLocaleHandler(value.value)
    }
    const domainData = useSelector(state => state.domainData.data)
    const topMenu01Items = domainData.Menu_Top
    const topMenu02Items = domainData.Menu_Top2
    const topMenu03Items = domainData.Menu_Top3
    const mainMenu01Items = domainData.Menu_Main
    const mainMenu02Items = domainData.Menu_Main2
    const mainMenu03Items = domainData.Menu_Main3
    const footerMenu01Items = domainData.Menu_Footer
    const footerMenu02Items = domainData.Menu_Footer2
    const footerMenu03Items = domainData.Menu_Footer3
    const options = () => {
        let array = []
        locales.forEach(locale => {
            array.push({ value: locale, label: locale, className: 'dropDownMenuItem' })
        });
        return array
    }
    const { locale } =  useSelector(
        (state) => ({
            locale: state.preferences.locale,
        }),
        shallowEqual
        );
    const defaultOption = locale;
    const locales = availableLocales.filter((local) => local !== locale);

    const handleSearch = (event) => {
        if(message == ""){
          event.preventDefault();
          // Do something else instead of navigating to a new page
          alert("Enter Search term")
        }
        navigate(`/search?q=${encodeURIComponent(message)}`);
      }
    
    const ScrollLink = ({ to, children }) => (
        <Link
            style={{ textDecoration: 'none' }}
            to={to.pathname}
            onClick={() => {
            const element = document.getElementById(to.hash.slice(1)); // Remove the '#' character from the anchor name
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
            }}
        >
            {children}
        </Link>
    );

    return (
        <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navigation w-nav">
            <div className="nav-wrapper" id='page-top'>
                {topMenu01Items && (
                    <div class="navigation-wrap-top">
                        <TopMenu items={topMenu01Items} customStyles={{type:"top", number:"01"}}/>
                    </div>
                )}
                <div className="navigation-items top-bar">
                    <div className="nav-domain-wrapper">
                        <ScrollLink to={{pathname: "/", hash: "#page-top"}}>
                            <div aria-current="page" className="logo-link main w-nav-brand w--current">
                                <div className="pagetitletext">{awcg_domain_u1}</div>
                            </div>
                        </ScrollLink>
                        <a href="#page-top" style={{ textDecoration: 'none' }}>
                            <div aria-current="page" className="logo-link w-nav-brand w--current">
                                <div className="text-block">{useFormatMessage('home.anydomain')}</div>
                            </div>
                        </a>
                    </div>
                    <div class="navigation-wrap">
                        <div class="div-block-2">
                            <div>{useFormatMessage('nav.search')}</div>
                            <div class="div-block-3">
                                <input
                                onKeyDown={(e) => {if (e.keyCode === 13){console.log(e.key); handleSearch()}}}
                                className='class="text-block-2'
                                type="text"
                                value={message}
                                placeholder={useFormatMessage('nav.searchPlaceholder')}
                                onChange={e => setMessage(e.target.value)}
                                /> 
                                {message ? (
                                <Link to={`/search?q=${message}#page-top`} className="search-button">
                                    <SearchOutlinedIcon onClick={handleSearch} />
                                </Link>
                                ) : (
                                <a disabled className="search-button">
                                    <SearchOutlinedIcon style={{ opacity: 0.5 }} />
                                </a>
                                )}
                            </div>
                        </div>
                        
                        {/* <nav role="navigation" class="navigation-items w-nav-menu"> */}
                            {/* <Link to={"search"} style={{ textDecoration: 'none' }}  class="navigation-item mobile w-nav-link">{useFormatMessage('nav.news')}</Link> */}
                            {/* <a href="#" class="navigation-item mobile w-nav-link">{useFormatMessage('nav.people')}</a> */}
                            {/* <a href="#" class="navigation-item mobile w-nav-link">{useFormatMessage('nav.about')}</a> */}
                            {/* <Dropdown controlClassName='dropdown-item'  menuClassName='dropDownMenu' options={options()} onChange={(value) => onSelect(value)} value={defaultOption} placeholder="Select an option" /> */}
                            {/* <a href="#" class="navigation-item last w-nav-link">{useFormatMessage('nav.contact')}</a> */}
                        {/* </nav> */}
                    {/* <div class="menu-button w-nav-button"><img src="images/menu-icon_1menu-icon.png" width="22" alt="" class="menu-icon"/></div> */}
                    </div>
                </div>
                {mainMenu01Items && (
                    <MainMenu items={mainMenu01Items} customStyles={{type:"main", number:"01"}}/>
                )}
                {mainMenu02Items && (
                    <MainMenu items={mainMenu02Items} customStyles={{type:"main", number:"02"}}/>
                )}
            </div>
            <div class="podm-wrapper">
                <div class="podm-container">
                    {/* <div class="part-of">part of</div>
                    <h1 class="dubai-magazine">2 Letter Domain Names</h1> */}
                </div>
            </div>
        </div>
    )
}

export default Navigation